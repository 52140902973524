import i18n from '../i18n/i18n'
import moment from 'moment'
import store from '@/store'
// import crypto from 'crypto-js'
import randombytes from 'randombytes'

export function isDevMode() {
  return process.env.NODE_ENV == 'production' ? false : true
}

export function diffSecs(utcTime) {
  if (!utcTime) return 0
  let timeStamp = new Date(utcTime).getTime()
  let now = new Date().getTime()
  return (now - timeStamp) / 1000  // return seconds
}

export function timeAgo(utcTime, timeSinceLastUpdated) {
  if (!utcTime) return ''
  let timeStamp = new Date(utcTime).getTime()
  let now = new Date().getTime()
  let diff = !timeSinceLastUpdated
    ? now - timeStamp
    : timeSinceLastUpdated * 1000
  let minute = 1000 * 60 // ms
  let hour = minute * 60
  let day = hour * 24
  let month = day * 30

  let secC = diff / 1000
  let minC = diff / minute
  let hourC = diff / hour
  let dayC = diff / day
  let monthC = diff / month
  
  let result = ''
  if (secC < 60) {
    result = '' + parseInt(secC) + ' ' + i18n.t('sec_ago') 
  } else if (minC < 60) {
    result = '' + parseInt(minC) + ' ' + i18n.t('min_ago')
  } else if (hourC < 24) {
    result = '' + parseInt(hourC) + ' ' + i18n.t('hour_ago')
  } else if (dayC < 30) {
    result = '' + parseInt(dayC) + ' ' + i18n.t('day_ago')
  } else {
    result = '' + parseInt(monthC) + ' ' + i18n.t('month_ago')
  }

  return result
}

// 函式getDefaultDateRange: 取得預設的日期範圍
// 輸入參數 nDays: 日期範圍的天數
// 輸出參數: [startDate, now]
export function getDefaultDateRange(nDays) {
  let now = new Date()
  let startDate = new Date()
  startDate.setDate(now.getDate() - nDays)
  return [startDate, now]
}

// Helper function to generate formatted datetime string
export function genFormattedDatetime(offset, unit, startOf='day') {
  return moment()
    .startOf(startOf)
    .add(offset, unit)
    .tz(store.getters.timezone)
}

export function genKeepDayOptions() {
  // 保留時間
  // 因為刑事局驗收有一條是資料要保留半年, 但半年會超過 180 天. 所以選單這邊一率改為 185
  // 30->35, 60->65,90->95 以此類推
  // 設備保留天數修改, 365 天改為 370 天
  let keepDays = [0, 3, 7, 15, 35, 65, 95, 185, 370]
  let keepOptions = []
  // // keepOptions.push({ value: 0, label: `365 ${i18n.t('day')}` })
  // for (let i = 0; i <= 365; i++)
  //   keepOptions.push({ value: i, label: `${i} ${ (i > 1) ? i18n.t('day') : i18n.t('one_day')}` })
  keepOptions = keepDays.map((days) => {
    return {
      value: days,
      label: `${days} ${i18n.t('day')}`
    }
  })

  return keepOptions;
}

export function genMinutesOptions(oneStr, multiStr) {
  const minOptions = []

  for (let i = 1; i <= 60; i++) {
    minOptions.push({
      value: `${i}`,
      // label: `${i} ${ (i > 1) ? i18n.t('min') : i18n.t('one_min') }`})
      label: `${i} ${i > 1 ? oneStr : multiStr}`
    })
  }

  return minOptions
}

// Account ID
export const accSpecialChars = '@-_.'
export const accLen = {
  min: 3,
  max: 64
}
export function validateAccountLen(accountId) {
  // 長度需3~64個字元
  if (accountId.length > 0 && accountId.length < accLen.min) {
    return false
  } else if (accountId.length > accLen.max) {
    return false
  }

  return true
}
export function validateAccountIDChar(accountId) {
  if (accountId.length <= 0) {
    return true
  }

  // 包含英數, 大小寫, 特殊字元 (@-_.)
  let ret = true
  const digital = '1234567890'
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowerCase = upperCase.toLocaleLowerCase()

  const chars = digital + upperCase + lowerCase + accSpecialChars
  for (const char of accountId) {
    if (!chars.includes(char)) {
      ret = false
      break
    }
  }

  return ret
}

// 密碼
export const pswSpecialChars = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
export const pswLen = {
  min: 8,
  max: 16
}
export function validatePwsLen(password) {
  // 長度需8~16個字元
  if (password.length > 0 && password.length < pswLen.min) {
    return false
  } else if (password.length > pswLen.max) {
    return false
  }
  return true
}
export function validatePswChar(password) {
  if (password.length <= 0) {
    return true
  }

  // 包含英數, 大小寫 => 檢查中文
  let ret = true
  const digital = '1234567890'
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowerCase = upperCase.toLocaleLowerCase()
  const chars = digital + upperCase + lowerCase + pswSpecialChars

  for (const char of password) {
    if (!chars.includes(char)) {
      ret = false
      break
    }
  }

  return ret
}
export function validatePswSpecialChar(password) {
  if (password.length <= 0) {
    return true
  }

  // 任一特殊字元 (~@_/+:)
  let ret = false
  const chars = pswSpecialChars

  for (const char of password) {
    if (chars.includes(char)) {
      ret = true
      break
    }
  }

  return ret
}

// 群組名
export const groupLen = { ...accLen }
export function validateGroupNameLen(groupName) {
  // 長度需3~64個字元
  if (groupName.length > 0 && groupName.length < groupLen.min) {
    return false
  } else if (groupName.length > groupLen.max) {
    return false
  }

  return true
}

export function vertifyStrLen(str, len) {
  return str && str.length > 0 && str.length >= len
}

/******************************************************************************
 *
 * @param {Array} treeList: tree list data
 * @param {Number} gId: group id
 * @returns {Object}
 */
export const getNode = (treeList, gId) => {
  for (const node of treeList) {
    if (node.id === String(gId)) {
      return node
    }

    if (node.children && node.children.length > 0) {
      const newNode = getNode(node.children, gId)
      if (newNode) {
        return newNode
      }
    }
  }
}

/******************************************************************************
 *
 * @param {Object} tree: tree object data
 * @returns {Array}
 */
export const treeToList = (tree) => {
  const result = []

  if (tree) {
    result.push(tree)
    const children = tree.children || []
    children.forEach((child) => {
      const childResult = treeToList(child)
      result.push(...childResult)
    })
  }

  return result
}

export const getAllLastNodes = (treeArray, result) => {
  // 遍历树形数组中的每个节点
  treeArray.forEach((node) => {
    // 如果当前节点没有子节点，则将其添加到结果数组中
    if (!node.children || node.children.length === 0) {
      result.push(node)
    } else {
      // 否则，递归调用 getAllLastNodes 函数获取当前节点的子节点中的最后节点
      getAllLastNodes(node.children, result)
    }
  })

  // 返回所有最后节点的数组
  return result
}

const getParent = (parentTreeList, gId) => {
  for (const node of parentTreeList) {
    if (node.children.map((kid) => kid.id).includes(gId)) {
      const { id, name } = node
      return { id, name }
    }

    if (node.children.length > 0) {
      const newNode = getParent(node.children, gId)
      if (newNode) {
        return newNode
      }
    }
  }
}

export const getAncestorList = (treeList, gId) => {
  const ancestor = []

  let parent = getParent(treeList, `${gId}`)
  while (parent) {
    ancestor.push(parent)
    parent = getParent(treeList, parent.id)
  }
  // Add self group
  const selfGrp = getNode(treeList, gId)
  if (selfGrp) {
    ancestor.unshift({ id: selfGrp.id, name: selfGrp.name })
  }

  // 最後一個最上層
  return ancestor
}

export const getNodeFamily = (treeList, gId) => {
  const self = getNode(treeList, gId)
  const descendants = treeToList(self)

  return descendants
}
export const getNodeKids = (treeList, gId) => {
  const self = getNode(treeList, gId)
  const descendants = treeToList(self)
  descendants.splice(0, 1) // Delete self

  return descendants
}
export const filterTreeByName = (tree, name) => {
  // 遞迴遍歷樹結構
  function traverse(node) {
    if (node.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) {
      // 若節點名稱符合，回傳該節點及其子節點
      return node
    }
    if (node.children && node.children.length > 0) {
      // 遞迴處理子節點
      const filteredChildren = node.children.map(child => traverse(child)).filter(Boolean)
      // 建立新的節點物件，只保留過濾後的子節點
      if (filteredChildren.length > 0) {
        return { ...node, children: filteredChildren }
      }
    }
    // 若節點名稱不符合且無子節點，則回傳 null
    return null
  }

  // 建立過濾後的樹結構
  const filteredTree = tree.map(node => traverse(node)).filter(Boolean)
  return filteredTree
}
export const sumNum = (nums) => {
  let sum = 0;

  for (const val of nums) {
    sum += val
  }

  return sum
}
/******************************************************************************
 *
 * @param {Boolean} isDev: is development mode or noe
 * @returns {console}
 * @example `console.set(true)` to open all console.log. Please remember to make it before release.
 */
// export const console = {
//   isDev: true, // false: 關掉所有 console.log
//   set(isDev) {
//     this.isDev = isDev
//   },
//   get() {
//     return this.isDev
//   },
//   log(...args) {
//     if (!this.isDev) return
//     window.console.log(`[Debug]`, ...args)
//   },
//   error(...args) {
//     // if (!this.isDev) return
//     window.console.error(...args)
//   },
//   warn(...args) {
//     if (!this.isDev) return
//     window.console.warn(...args)
//   },
//   time(...args) {
//     if (!this.isDev) return
//     window.console.time(...args)
//   },
//   timeLog(...args) {
//     if (!this.isDev) return
//     window.console.timeLog(...args)
//   },
//   timeEnd(...args) {
//     if (!this.isDev) return
//     window.console.timeEnd(...args)
//   }
// }

/******************************************************************************
 *
 * @param {Number} ms: micro seconds
 * @returns {Promise}
 */
export const sleep = (ms) => {
  return new Promise((resolve /*, reject*/) => {
    if (!ms || ms < 0) {
      ms = 0
    }
    setTimeout(resolve(), ms)
  })
}

// 時間格式規範：https://zh.wikipedia.org/zh-cn/ISO_8601
export const YYYYMMDD = 'YYYY-MM-DD'
const HHmm = 'HH:mm'
export const YYYYMMDDHHmm = `${YYYYMMDD} ${HHmm}`
export const HHmmss = `${HHmm}:ss`
export const YYYYMMDDHHmmss = `${YYYYMMDD} ${HHmmss}`
export const formatTime = (time) => {
  if (!time) return ''
  return moment(time).tz(store.getters.timezone).format(YYYYMMDDHHmmss)
}

/******************************************************************************
 *
 * @param {Date} time
 * @returns {String} `YYYY-MM-DD hh:mm` format time string
 */
export const formatTimeNoSec = (time) => {
  let now = formatTime(time)

  if (!now) return now

  let items = now.split(':')
  items.pop()
  return items.join(':')
}
/******************************************************************************
 *
 * @param {Date} time
 * @returns {String} `YYYY-MM-DD` format time string
 */
export const formatTimeNoTime = (time) => {
  let now = formatTime(time)

  if (!now) return now

  return now.split(' ').shift()
}

export const ms2hhmmss = (ms) => {
  if (ms <= 0) {
    return `00:00:00`
  }

  const hr = Math.floor(ms / 3600000)
  const rmdHr = ms % 3600000
  const min = Math.floor(rmdHr / 60000)
  const rmdMin = rmdHr % 60000
  const sec = Math.floor(rmdMin / 1000)
  // const mSec = rmdMin % 1000;

  const hh = hr.toString().length < 2 ? `00${hr}`.slice(-2) : hr
  const mm = `00${min}`.slice(-2)
  const ss = `00${sec}`.slice(-2)

  return `${hh}:${mm}:${ss}`
}

export const enum2Options = (enumObj) => {
  return Object.keys(enumObj).map((key) => {
    return {
      label: key,
      value: enumObj[key]
    }
  })
}

/***************************************************************************
 * 這個 function 會改變 treeObj 的結構
 * 1. 先將 treeObj 的 children 依照 group.name 排序
 * 2. 再將 children 的 children 依照 group.name 排序
 * 3. 以此類推
 * @param {*} treeObj 
 */
export const sortTreeGroup = (treeObj) => {
  if (treeObj.children && treeObj.children.length > 0) {
    treeObj.children.sort((a, b) => {
      return a.group.name.localeCompare(b.group.name)
    })

    treeObj.children.forEach((child) => {
      sortTreeGroup(child)
    })
  }
}

/******************************************************************************
 * 
 * @param {array} userList 
 * @param {object} groupTree 
 * @param {string} labelKey videoTitle / infoName
 * @param {string} filterKey 
 * @returns {array} filtered user tree list 
 */
export function getFilterUserTreeList(userList, groupTree, filterKey = '') {
  const users = userList.map(user => {
    let showName = user.kind === 0 || user.kind === 2 ? user.video.title : user.info.name
    return {
      id: user.id,
      index: String(user.index),
      name: showName,
      groupId: user.groupId,
      label: showName + ' (' + user.id + ')',
      deviceModelId: user.deviceModelId,
      kind: user.kind
    }
  })

  const treeData = []

  let arrUsers = getUserListByGroupId(
    users,
    groupTree.group.id,
    groupTree.group.name,
    filterKey
  )

  let arrChildren = getChildrenData(
    groupTree.children,
    users,
    groupTree.group.id,
    filterKey
  )

  let tmp = {
    id: groupTree.group.id, 
    groupId: groupTree.group.id, // tree的資料須有一樣的結構，故將id設給groupId
    label: groupTree.group.name, // 群組只顯示群組名稱
    children: arrUsers.concat(arrChildren),
    parent: 0
  }
  treeData.push(tmp)
  return treeData
}

/******************************************************************************
 * 取得過濾後該節點的 user list
 * @param {*} userList 
 * @param {*} NodeGroupId 
 * @param {*} NodeGroupName 
 * @param {*} filterKey 
 * @returns 
 */
function getUserListByGroupId(userList, NodeGroupId, NodeGroupName, filterKey) {
  let fItem = filterKey.toLowerCase() // 轉換為小寫
  // 先檢查NodeGroupName是否含有fItem，若有則返回此節點的所有user;若無則進行search的比對
  let bGroupMatch
  if (NodeGroupName) bGroupMatch = NodeGroupName.toLowerCase().includes(fItem)
  let arrUsers = userList.filter((user) => {
    if (filterKey && !bGroupMatch) {
      return (
        user.groupId == NodeGroupId &&
        (user.label.toLowerCase().includes(fItem) ||
          user.id.toLowerCase().includes(fItem))
      )
    } else {
      return (
        user.groupId == NodeGroupId 
      )
    }
  })

  return arrUsers
}

/******************************************************************************
 * 取得過濾後該節點的 children list
 * @param {*} data 
 * @param {*} userList 
 * @param {*} groupId 
 * @param {*} filterKey 
 * @returns 
 */
function getChildrenData(data, userList, groupId, filterKey) {
  let arrChildren = []
  if (data) {
    data.forEach((item) => {
      let arrUsers = getUserListByGroupId(userList, item.group.id, item.group.name, filterKey)

      let arrNode = item.children
        ? getChildrenData(item.children, userList, item.group.id, filterKey)
        : []

      let fItem = filterKey.toLowerCase() // 轉換為小寫
      let bInclude =
        fItem != '' &&
        (item.group.id.toLowerCase().includes(fItem) ||
          item.group.name.toLowerCase().includes(fItem))

      if (fItem !== '') {
        // 若有filterKey，則只顯示符合條件的節點(設備)
        if (arrUsers.length == 0 && arrNode.length == 0) {
          return []
        }
      }
      if (arrUsers.length > 0 || arrNode.length > 0 || bInclude) {
        arrChildren.push({
          id: item.group.id,
          groupId: item.group.id,
          label: item.group.name, // 群組只顯示群組名稱
          children: arrUsers.concat(arrNode),
          parent: groupId
        })
      }
    })
  }
  return arrChildren
}

export function getFilterUserTreeList2(userList, groupTree) {
  console.log(`[getFilterUserTreeList2] userList:`, userList)
  console.log(`[getFilterUserTreeList2] groupTree:`, groupTree)

  return []
}

// 秒數格式化
export function formatSeconds(totalSeconds) {
  let hours = Math.floor(totalSeconds / 3600)
  totalSeconds %= 3600
  let minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds % 60
  minutes = String(minutes).padStart(2, "0")
  hours = String(hours).padStart(2, "0")
  seconds = String(seconds).padStart(2, "0")
  return hours + ":" + minutes + ":" + seconds
}

/****************************************************************************
 * convert-size-in-bytes-to-kb-mb-gb
 * @param {*} bytes 
 * @param {*} decimals 
 * @returns 
 */
export const formatBytes = (bytes, decimals = 2) => {
  if(!+bytes) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${ parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) } ${sizes[i]}`
}

/****************************************************************************
 * convert-size-in-bps-to-kbps-mbps-gbps
 * @param {*} bitrate 
 * @param {*} decimals 
 * @returns 
 */
export const formatBitrate = (bps, decimals = 2, unit = 'bps') => {
  if(!+bps) return '0 bps'
  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']

  const i = Math.floor(Math.log(bps) / Math.log(k))
  return `${ parseFloat((bps / Math.pow(k, i)).toFixed(dm)) } ${sizes[i]}${unit}`
}

export const formatKiloBitrate = (bitrate) => {
  let br = `${bitrate}K`
  if (bitrate >= 1000) {
    br = `${Math.floor(bitrate / 1000)}M`
  }
  return `${br}bps`
}


export function getScore(score) {
  // 取小數點後兩位無條件捨去
  return Math.floor(score * 100) / 100
}

export function getAge(birthday) {
  if (!birthday) return ''
  // 計算年齡
  const today = new Date()
  const birthDate = new Date(birthday)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age + 'Y'
}

// 字串比對：模糊比對
export function FuzzyStrComp(str1, str2) {
  if (!str1 || !str2) return true

  const s1 = str1.toLocaleLowerCase()
  const s2 = str2.toLocaleLowerCase()

  return s1.includes(s2) || s2.includes(s1)
}
// 排序：A -> Z => 小 -> 大(Increment 遞增)
export function A2ZSort(a, b) {
  if (a > b) return 1
  else if (a < b) return -1
  else return 0
}
// 排序：Z -> A => 大 -> 小(Decrease 遞減)
export function Z2ASort(a, b) {
  if (a < b) return 1
  else if (a > b) return -1
  else return 0
}

export function generateUUID() {
  // 方法一：因為使用 Math.random(), 被弱掃找到 Insecure Randomness 風險
  // let d = new Date().getTime()
  /*'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    }
  )*/

  // 方法二：因為使用 window.crypto.randomUUID(), 需要 Nodejs v19 才支援; Vue2 專案目前使用 Nodejs v16
  // Nodejs 要 v19.0.0 才支援 crypto.randomUUID()
  // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
  // let uuid = window.crypto.randomUUID()

  // 方法三：使用 Nodejs 原生 crypto 產生亂數UUID
  // 生成 16 個隨機字節
  const rdmBytes = randombytes(16) //crypto.randomBytes(16) => 與內部的 utils/crypto.js 打架了...

  // 設置 UUID 版本 (4) 和變體 (8, 9, A, 或 B)
  rdmBytes[6] = (rdmBytes[6] & 0x0f) | 0x40 // 設置版本為 4
  rdmBytes[8] = (rdmBytes[8] & 0x3f) | 0x80 // 設置變體為 8, 9, A, 或 B

  // 將字節數據轉換為 UUID 格式
  const _uuid = rdmBytes.toString('hex')
  let uuid = `${_uuid.slice(0, 8)}-${_uuid.slice(8, 12)}-4${_uuid.slice(13, 16)}-${_uuid[16]}${_uuid.slice(17)}`
  return uuid
}

export function genRandom() {
  // Note:應應弱掃 Insecure Randomness 風險, 用 window.crypto.getRandomValues() 取代 Math.random() 取得隨機數值
  // JavaScript的Math.random()函式生成偽隨機數，通常用於非加密目的。
  // 如果你需要更安全的隨機性，特别是用於加密或安全應用程序，你應該使用 crypto.getRandomValues() 函式，他提供了更安全的隨機數生成方式。

  // // [NodeJs v1.15 以上的用法]
  // // 創建一個Uint8Array來存儲隨機數
  // const randomBytes = new Uint8Array(4) // 這裡的4表示你需要生成4字節的隨機數，可以根據需要調整長度
  // // 使用crypto.getRandomValues()生成安全的隨機數
  // // Nodejs 要 v15.0.0 才支援 crypto.getRandomValues()
  // window.crypto.getRandomValues(randomBytes)
  // // 將隨機字節轉換為數字（0到255之間）
  // const randomNumber = randomBytes[0]
  // return randomNumber

  // [NodeJs v1.14 以上的用法]
  const { min, max } = { min: 0, max: 255 }
  const range = max - min + 1
  const randomBytes = crypto.randomBytes(4) // 使用4個位元組
  const randomValue = randomBytes.readUInt32LE(0)

  return min + (randomValue % range)
}

export function getEventTitle(eventType) {
  // eventType: lpr, chased, sos, fr, video, or
  switch (eventType) {
    case 'lpr':
      return i18n.t('event_lpr')
    case 'fr':
      return i18n.t('event_fr')
    case 'chased':
      return i18n.t('event_chased')
    case 'sos':
      return i18n.t('event_sos_title')
    case 'video':
      return i18n.t('event_video')
    case 'or':
      return i18n.t('event_or')
    default:
      return ''
  }
}

export function getEventFilename(event) {
  // 車牌事件: <車號>_<事件時間>_<設備帳號(設備帳號)>.png
  // 人臉事件: <人物名稱>_<事件時間>_<設備帳號(設備帳號)>.png
  // SOS事件: SOS_<事件時間>_<設備帳號(設備帳號)>.png
  // 影片事件: video_<事件時間>_<設備帳號(設備帳號)>.png
  const [type] = event.uid.split('-') // type: lpr, fr, urg, sos, video
  if (type === 'sos') {
    const gpsTimestamp = moment(event.gps.timestamp).tz(store.getters.timezone).format('YYYY-MM-DD HH-mm-ss')
    const device = store.getters['account/getDeviceTitleId'](event.userAccount)
    return `SOS_${gpsTimestamp}_${device}.png`
  } else if (type === 'video') {
    const time = moment(event.startTime).tz(store.getters.timezone).format('YYYY-MM-DD HH-mm-ss')
    const deviceAccount = `${event.title}(${event.user.id})`
    return `Video_${time}_${deviceAccount}.png`
  } else {
    const time = moment(event.detectTime).tz(store.getters.timezone).format('YYYY-MM-DD HH-mm-ss')
    const deviceAccount = store.getters['account/getDeviceTitleId'](event.user.id)

    if (type === 'lpr' || type === 'urg') {
      return `${event.triggered[0].content}_${time}_${deviceAccount}.png`
    } else if (type === 'fr') {
      const selectedTrig = event.triggered.find((t) => t.rank === store.state.historyFr.rank)
      const name = selectedTrig ? selectedTrig.name : i18n.t('unknown')
      return `${name}_${time}_${deviceAccount}.png`
    }
    
    return `${time}_${deviceAccount}.png`
  }
}

// 無條件捨去
export function roundDown(num, decimal) {
  return Math.floor( ( num + Number.EPSILON ) * Math.pow( 10, decimal ) ) / Math.pow( 10, decimal )
}

// tag 排序
export function sortTag(tagList) {
  if (!tagList) return []

  // 排序：先排 locked, 再排名稱
  const locked = tagList.filter((t) => t.locked).sort((a,b) => {
    return a.name > b.name ? 1 : -1
  })

  const notLocked = tagList.filter((t) => !t.locked).sort((a,b) => {
    return a.name > b.name ? 1 : -1
  })

  let tmpTagList = [ ...locked, ...notLocked]
  return tmpTagList
}
// 不能刪除 人物資訊 條件
export function cannotDeleteFrCond(fr, tagList) {
  if (!fr) return false

  // * 該 fr.imported === 1 => ∵ 匯入的fr
  if (fr.imported === 1) return true

  let frTagList = tagList.filter((t) => fr.tag.includes(t.id))
  // * 該 fr.tag 有 locked tag 時 => ∵ 匯入的tag
  if (frTagList.filter((t) => t.locked).length > 0) return true

  return false
}


/** 
 * 回傳 obj 中所有的 key，包括巢狀的 key
 * @param {obj} 
 * @returns {keys} 
 */
export const getDeepKeys = (obj) => {
  const keys = []
  for (const key in obj) {
    keys.push(key)
    if (typeof obj[key] === 'object') {
      const subkeys = getDeepKeys(obj[key])
      keys.push(...subkeys.map((subkey) => `${key}.${subkey}`))
    }
  }
  return keys
}


export function getResolution(width, height) {
  // 顯示解析度列表
  // https://zh.wikipedia.org/zh-tw/%E6%98%BE%E7%A4%BA%E5%88%86%E8%BE%A8%E7%8E%87%E5%88%97%E8%A1%A8
  if (width >= 7680 && height >= 4320) {
    return '8k';
  } else if (width >= 3840 && height >= 2160) {
    return '4k';
  } else if (width >= 2560 && height >= 1440) {
    return '2k';
  } else if (width >= 1920 && height >= 1080) {
    return '1080p';
  } else if (width >= 1280 && height >= 720) {
    return '720p';
  } else if (width >= 854 && height >= 480) {
    return '480p';
  } else if (width >= 720 && height >= 480) {
    return 'D1 ';
  } else if (width >= 704 && height >= 576) {
    return '4CIF ';
  } else if (width >= 640 && height >= 480) {
    return 'VGA ';
  } else if (width >= 640 && height >= 360) {
    return '360p';
  } else if (width >= 426 && height >= 240) {
    return '240p';
  } else if (width >= 320 && height >= 240) {
    return 'QVGA ';
  } else {
    return `${width} x ${height} `;
  }
}

/**
 * 取得字串的字元數
 * @param {*} str 字串
 * @returns {Number} 字元數
 */
export function getStringCharCount(str) {
  return str.replace(/[^\x00-\xff]/g,"xx").length
}

/**
 * 取得更新時間顏色
 * @param {*} time Date || Number
 * @returns {String} 顏色名稱
 */
export function getUptimeColor(updatedTime, timeSinceLastUpdated) {
  if (!updatedTime) {
    return 'green'
  }

  const diff = timeSinceLastUpdated //diffSecs(updatedTime)
  if (diff > 30 && diff <= 60) {
    return 'orange'
  } else if (diff > 60) {
    return 'red'
  }

  return 'green'
}
