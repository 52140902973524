// import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import crypto from '../utils/crypto'

// API methods enum
export const ApiMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

// 如果開發中就用博遠serer 如果是安裝在客戶方 就用客戶server
let { protocol, hostname } = location
export let base_apiurl = (process.env.NODE_ENV == 'production') ? protocol + '//' + hostname : `https://${process.env.VUE_APP_API_HOSTNAME}`
export let domain_name = (process.env.NODE_ENV == 'production') ? hostname : process.env.VUE_APP_API_HOSTNAME
// let base_apiurl = 'https://develop.bovia.com.tw' // nginx測試用


// // 因為需要使用到 base_apiurl, 所以在這才增加
// async function createMetaHttpEquiv() {
//   const meta = document.querySelector('head meta[http-equiv="Content-Security-Policy"]')
//   console.log(`[API] meta:`, meta)
//   console.log(`[API] meta:`, meta?.content)
//   console.log(`[API] base_apiurl:`, base_apiurl)
//   const content = `default-src 'self';
//   script-src 'self';
//   style-src 'self';
//   img-src 'self' data: ${base_apiurl};
//   connect-src 'self'; ${base_apiurl} data: blob:;
//   media-src 'self'; ${base_apiurl};
//   `
//   console.log(`[API] content:`, content)
//   // meta.content = content
// }
// createMetaHttpEquiv()

axios.defaults.timeout = 30000
axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.get['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Accept'] = 'application/json'
// axios.defaults.headers.Accept = 'application/json'
axios.defaults.baseURL = base_apiurl

const apiClient = axios.create({
  baseURL: base_apiurl,
  timeout: 30000,
})

// axios request 攔截器
apiClient.interceptors.request.use(
  (config) => {
    const { accessToken } = store.state

    if (accessToken) {
      // console.log(`[API] get accessToken`, config)
      config.headers.Authorization = `Bearer ${accessToken}`
    } /* else {
      const { isLogin } = store.state
      // F5: 登入過（isLigin === true） 且 沒有accessToken(!accessToken) 且 不是登入頁面（url !== '/login'）
      if (isLogin && config.url !== '/login' && !orgConfig) {
        // 暫存原有的 url
        orgConfig = {
          method: config.method,
          url: config.url
        }
        config.method = 'post'
        config.url = '/api/web/refresh'
        config.data = {
          refreshToken: crypto.decrypt(localStorage.getItem('refreshToken'))
        }
      }
    }*/
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// axios response 攔截器
apiClient.interceptors.response.use(
  (response) => {
    /*const { accessToken, isLogin } = store.state

    if (
      !accessToken &&
      isLogin &&
      response.config.url === '/api/web/refresh' &&
      orgConfig
    ) {
      const { accessToken, user, refreshToken } = response.data
      store.commit('setAccessToken', accessToken)
      store.commit('updatePermissionV2', user.permissionV2)
      store.commit('updateStaff', user.staff)
      localStorage.setItem('refreshToken', crypto.encrypt(refreshToken))
      // 刷新原始 request 的 access_token
      response.config.headers.Authorization = `Bearer ${accessToken}`

      // 重送 request (with new access_token)
      response.config.method = orgConfig.method
      response.config.url = orgConfig.url
      orgConfig = null
      return axios(response.config)
    }*/

    return response
  },
  (error) => {
    const { response } = error
    console.log(`[API] error:`, error)
    if (response) {
      switch (response.status) {
        case 400:
          if (error.config.url == '/api/web/refresh') {
            // [更新 access_token 失敗] ( e.g. refresh_token 過期無效
            store.commit('login', false)
            store.commit('setAccessToken', '')
            localStorage.setItem('refreshToken', '')
            console.log(`${response.status}: ${response.data.message}`)
            if (router.currentRoute.path !== '/login') router.push('/login')
          }
          break
        case 401:
          // 帳號對 密碼錯的時候 會回傳401 就會觸發/refresh 但/login不需要token
          // 所以如果是/login 就直接回傳錯誤 不呼叫/refresh
          if (error.config.url == '/login') {
            return Promise.reject(error)
          }
          // 如果refresh都不會回傳401 就可以把這行拔掉了
          if (error.config.url !== '/api/web/refresh') {
            const original_req_config = error.config
            const data = { refreshToken: crypto.decrypt(localStorage.getItem('refreshToken')) }
            return apiClient.post('/api/web/refresh', data)
              .then(res => {
                store.commit('setAccessToken', res.data.accessToken)
                store.commit('updatePermissionV2', res.data.user.permissionV2)
                store.commit('updateStaff', res.data.user.staff)
                localStorage.setItem('refreshToken', crypto.encrypt(res.data.refreshToken))
                // 刷新原始 request 的 access_token
                original_req_config.headers.Authorization =
                  'Bearer ' + res.data.accessToken

                // 重送 request (with new access_token)
                return apiClient(original_req_config)
              })
              .catch(error => {
                // [更新 access_token 失敗] ( e.g. refresh_token 過期無效
                store.commit('login', false)
                store.commit('setAccessToken', '')
                localStorage.setItem('refreshToken', '')
                console.log('更新token失敗: ', error.message )
                if (router.currentRoute.path !== '/login') router.push('/login')
              })
          }
          break;
          case 403:
              if (error.config.url == '/logout') {
                return Promise.reject(error)
              }
              break;
        default:
          // console.log(`API.RES error:`, error.config)
          console.log(error.response, `${error.response.status}: ${error.response.data.message}`)
      }
    }
    return Promise.reject(error)
  },
)

apiClient.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  let config = err.config
  // If config does not exist or the retry option is not set, reject
  if (!config || !config.retry) return Promise.reject(err)

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err)
  }

  // Increase the retry count
  config.__retryCount += 1

  // Create new promise to handle exponential backoff
  let backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1)
  })

  // Return the promise in which recalls axios to retry the request
  return backoff.then(function () {
    return apiClient(config)
  })
})

export default apiClient

export function apiCheckStatus(resp) {
  if (!resp) return true

  switch (resp.status) {
    case 200: // OK
    case 201: // Created
    case 204: // No Content
      return true
    default:
      return false
  }
}

// 應應 System Information Leak 的風險,
// API 的錯誤訊息, 都要轉換過錯誤訊息到 UI
export function apiErrorMsg(resp) {
  // console.log(`[apiErrorMsg] resp:`, resp)
  // const { code } = resp
  // if (code === 'ERR_NETWORK') {
  //   return 'api_network_error'
  // }

  const response = Object.keys(resp).includes('response') ? resp.response : resp
  // console.log(`[apiErrorMsg] response:`, response)
  if (response.status < 400) return null

  const { status, data, message } = response
  const msg = data ? data.message.toLowerCase() : message?.toLowerCase()

  if (status >= 400 && status < 500) {
    switch (status) {
      case 400:
        return 'api_400'
      case 401:
        if (msg.indexOf('Wrong username or password.'.toLowerCase()) >= 0) {
          return 'api_401_login' // 帳號對, 密碼錯
        } else if (msg.indexOf('Your account has been disabled.'.toLowerCase()) >= 0) {
          return 'api_401_account_disabled' // 帳號被停用
        } else if (msg.indexOf('Your account has been temporarily suspended due to multiple failed login or security policy.'.toLowerCase()) >= 0) {
          return 'api_401_account_suspended' // 帳號被暫停
        }
        return 'api_401' // 其他 帳號或密碼錯誤
      case 403:
        if (msg.indexOf('The password has been used in the last three attempts.'.toLowerCase()) >= 0) {
          return 'api_403_duplicate_password' // 該密碼已在最近 3 次內被使用
        }
        // else if (msg.indexOf('Certificate and key pair are not accepted'.toLocaleLowerCase()) >= 0) {
        //   return 'api_403_certificate_key_pair_not_accepted' // 憑證和密鑰不配對
        // }
        return 'api_403'
      case 404:
        if (msg.indexOf('Wrong username or password.'.toLowerCase()) >= 0) {
          return 'api_404_login' // 帳號錯
        } else if (msg.indexOf('Default setting not found.'.toLowerCase()) >= 0) {
          return 'api_404_default_setting' // 找不到設備的 系統預設 設定
        } else if (msg.indexOf('User setting not found.'.toLowerCase()) >= 0) {
          return 'api_404_user_setting' // 找不到設備的 設備客製 設定
        }
        return 'api_404'
      case 406:
        return 'api_406'
      default:
        return 'api_error'
    }
  } else if (status >= 500) {
    return 'api_500'
  }

  return 'api_error'
}

function apiGetParam(data) {
  let params = null
  if (data) {
    params = new URLSearchParams()
    Object.entries(data).forEach(([key, value]) => {
      // 處理陣列資料
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(key, item)
        })
      } else params.append(key, value)
    })
  }

  return params
}
export function apiGetUrl(url, data) {
  const params = apiGetParam(data)
  let tmlUrl = `${axios.defaults.baseURL}${url}`

  if (params) {
    return `${tmlUrl}?${params.toString()}`
  }
  return tmlUrl
}

export async function apiGet(url, data) {
  try {
    // console.log(`[GET] accessToken:`, store.state.accessToken)
    if (data) {
      const params = apiGetParam(data)
      return await apiClient.get(url, { params })
    }
    return await apiClient.get(url)
  } catch (err) {
    // Vue.prototype.$notify({
    //   type: 'error',
    //   message: err.response ? err.response.data.message : err
    // })
    return err.response ? err.response : err
  }
}

export async function apiPost(url, data) {
  try {
    // console.log(`[POST] accessToken:`, store.state.accessToken)
    if (data) {
      return await apiClient.post(url, data)
    }
    return await apiClient.post(url)
  } catch (err) {
    return err.response ? err.response : err
  }
}

export async function apiPostFile(url, data) {
  try {
    // console.log(`[POST File] accessToken:`, store.state.accessToken)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    if (data) {
      return await apiClient.post(url, data, config)
    }
    return await apiClient.post(url, config)
  } catch (err) {
    return err.response ? err.response : err
  }
}

export async function apiPut(url, data) {
  try {
    // console.log(`[PUT] accessToken:`, store.state.accessToken)
    if (data) {
      return await apiClient.put(url, data)
    }
    return await apiClient.put(url)
  } catch (err) {
    return err.response ? err.response : err
  }
}

export async function apiDelete(url, data) {
  try {
    // console.log(`[DELETE] accessToken:`, store.state.accessToken)
    if (data) {
      return await apiClient.delete(url, data)
    }
    return await apiClient.delete(url)
  } catch (err) {
    return err.response ? err.response : err
  }
}

export function apiLogin(data) {
  return apiClient.post('/api/web/login', data)
}

export function apiLogout() {
  return apiClient.post('/api/web/logout')
}

export function apiRefresh(data) {
  return apiClient.post('/api/web/refresh', data)
}

export function apiSsoLogin(data) {
  return apiClient.post(`${base_apiurl}/api/npa/sso-login`, data)
}

// public: 0: 私有 1: 公開
export function apiGetUserList(kind = 'device', publicParam = null) {
  let url = ''
  if (kind === 'device') url = '/api/web/user/list?kinds=0&kinds=2'
  else if (kind === 'user') url = '/api/web/user/list?kinds=0&kinds=1&kinds=3'
  else url = '/api/web/user/list' // 導覽欄 > 通訊錄 (人&設備都要顯示)
  if (publicParam !== null) url += (!kind ? `?` : `&`) + `public=${publicParam}`
  return apiClient.get(url)
}

export function apiGetUserConfig(id) {
  return apiClient.get('/api/web/user/config', { params: { id: id } })
}

// 2fa
export async function apiPostUser2fa(code) {
  // return await apiPost('/user/2fa', { code }) // 棄用
  return await apiPost('/api/web/user/2fa/verify', { code })
}

export async function apiPostUser2faGenerate() {
  return await apiPost('/api/web/user/2fa/generate')
}
export async function apiPostUser2faEnable(code) {
  return await apiPost('/api/web/user/2fa/enable', { code })
}
export async function apiPostUser2faDsiable(code) {
  return await apiPost('/api/web/user/2fa/disable', { code })
}

export async function apiGetGroup(id) {
  return await apiGet('/api/web/group?id=${id}')
}

export function apiCreateGroup(data) {
  return apiClient.post('/api/web/group', data)
}

export function apiEditGroup(data) {
  return apiClient.put('/api/web/group', data)
}

export function apiDeleteGroup(id) {
  return apiClient.delete('/api/web/group', { id })
}

export function apiGetGroupTree() {
  return apiClient.get('/api/web/group/tree')
}

// Used at account pages
export async function apiGetGroupInfoList() {
  // 如果使用者沒有群組(role.permission.group)的權限, 後台會回傳403
  return await apiGet('/api/web/group-info/list')
}

export function apiGetUserTree() {
  return apiClient.get('/api/web/user/tree')
}

export function apiGetGroupList() {
  return apiClient.get('/api/web/group/list')
}

export function getDevices() {
  return apiClient.get('/api/web/device/list')
}

// 設備類型
export function apiGetDeviceModels() {
  return apiGet('/api/web/device/models')
}

export function apiGetLiveList() {
  return apiClient.get('/api/web/live/list')
}

export function apiGetServerList() {
  return apiClient.get('/api/web/server/list')
}

export function apiSearchBoviaLprEvent(data) {
  let retryCount = data.retry ? data.retry : 10000
  return apiClient.post('/api/web/va/bovia/lpr/event/search', data, { retry: retryCount, retryDelay: 5000 })
}

export function apiGetBoviaLprEvent(id) {
  return apiClient.get(`/api/web/va/bovia/lpr/event?id=${id}`)
}

export function apiGetBoviaLprEvents(params) {
  return apiClient.get(`/api/web/va/bovia/lpr/events`, { params: params })
}

export function apiGetBoviaLprEventsCount(params) {
  return apiClient.get(`/api/web/va/bovia/lpr/events/count`, { params: params })
}

export function apiGetBoviaLprEventsBasic(params) {
  return apiClient.get('/api/web/va/bovia/lpr/events/basic', { params: params })
}

export function apiGetBoviaLprEventsBasicCount(params) {
  return apiClient.get('/api/web/va/bovia/lpr/events/basic/count', { params: params })
}

export function apiGetBoviaLprEventsAdvanced(params) {
  return apiClient.get('/api/web/va/bovia/lpr/events/advanced', { params: params })
}

export function apiGetBoviaLprEventsAdvancedCount(params) {
  return apiClient.get('/api/web/va/bovia/lpr/events/advanced/count', { params: params })
}

/* 查詢人物事件 */
export function apiGetBoviaFrEvents(params) {
  return apiClient.get(`/api/web/va/bovia/fr/events`, { params: params })
}

export function apiGetBoviaFrEventsCount(params) {
  return apiClient.get(`/api/web/va/bovia/fr/events/count`, { params: params })
}

/** 查詢物件辨識即時事件 */
export function apiGetBoviaOrEventsDashboard(params) {
  return apiGet('/api/web/va/bovia/or/events/dashboard', params)
}

/** 查詢物件辨識歷史事件 Get Bovia OR Events */
export function apiGetBoviaOrEvents(params) {
  return apiGet('/api/web/va/bovia/or/events', params)
}

/** 查詢物件辨識事件筆數 Get Bovia OR Events Count */
export function apiGetBoviaOrEventsCount(params) {
  return apiGet('/api/web/va/bovia/or/events/count', params)
}

// Get SOS Event
export function apiGetSosEvent(id) {
  return apiClient.get(`/api/web/sos/event?id=${id}`)
}

// Get SOS Events
export function apiGetSosEvents(params) {
  return apiClient.get('/api/web/sos/events', { params: params })
}

// Get SOS Events Count
export function apiGetSosEventsCount(params) {
  return apiClient.get('/api/web/sos/events/count', { params: params })
}

/* 一般搜尋匯出 */
export function apiBoviaLprEventsBasicReport(data) {
  // photo: 小圖, snapshot: 大圖, embed: 嵌入小圖至Excel => 等有UI稿, 再做拆分
  // const tmpData = { ...data, ...{ photo: 1, snapshot: 1, embed: 1 } }
  return apiClient.post('/api/web/va/bovia/lpr/events/basic/report', data)
}

/* 進階搜尋匯出 */
export function apiBoviaLprEventsAdvancedReport(data) {
  // photo: 小圖, snapshot: 大圖, embed: 嵌入小圖至Excel => 等有UI稿, 再做拆分
  // const tmpData = { ...data, ...{ photo: 1, snapshot: 1, embed: 1 } }
  return apiClient.post('/api/web/va/bovia/lpr/events/advanced/report', data)
}

/* 回報匯出狀態API */
export function apiGetBoviaLprEventsReports() {
  return apiClient.get('/api/web/va/bovia/lpr/events/reports')
}

/* 刪除報告 */
export function apiDeleteBoviaLprEventsReport(id) {
  return apiClient.delete('/api/web/va/bovia/lpr/events/report', { data: { id: id } })
}

/* 車號績效報表：LPR Report Permission */
export async function apiGetBoviaLprPerformanceReports() {
  const res = await apiGet('/api/web/va/bovia/lpr/performance/reports')
  if (res.status === 200) {
    const newResData = res.data.filter((rpt) => rpt.system !== 1)
    res.data = newResData
    return res
  }
  return res
}
export function apiGetBoviaLprPerformanceReport(data) {
  return apiGet('/api/web/va/bovia/lpr/performance/report', data)
}

export function apiDeleteBoviaLprPerformanceReport(id) {
  return apiDelete('/api/web/va/bovia/lpr/performance/report', { data: { id } })
}
/* 車號使用紀錄： Audit Permission*/
export function apiGetAuditsReports() {
  return apiGet('/api/web/audits/reports')
}
export function apiDeleteAuditsReport(id) {
  return apiDelete('/api/web/audits/report', { data: { id } })
}

// function rmMisjudgedEvt(events) {
//   return events.filter((evt) => evt.misjudged === 0) // 0: 非誤判
// }
function sortEvnetNew2Old(events) {
  // 新([0]) 至 舊([len - 1])
  // API 來的資料不會有 .timestamp 的資料, web 整過的才有
  return events.sort((a, b) => new Date(b.detectTime).getTime() - new Date(a.detectTime).getTime())
}
export async function apiGetBoviaLprEventsDashboard(params) {
  // return apiClient.get('/api/web/va/bovia/lpr/events/dashboard', { params: params })
  const res = await apiGet('/api/web/va/bovia/lpr/events/dashboard', params)
  if (res.status === 200) {
    // let data = rmMisjudgedEvt(res.data) // 過濾掉誤判
    let data = res.data
    let data2 = null
    if (params?.beforeId?.length > 0 && data.length > 0) { // 因為beforeId回傳 舊([0])至新([len - 1]), 所以要排成 新([0])至舊([len - 1]) 方便整理資料
      data2 = sortEvnetNew2Old(data) // 時間排序
    }

    res.data = data2 ? data2 : data
    return res
  }
  return res
}

export async function apiGetBoviaFrEventsDashboard(params) {
  // return apiClient.get('/api/web/va/bovia/fr/events/dashboard', { params: params })

  const res = await apiGet('/api/web/va/bovia/fr/events/dashboard', params)
  if (res.status === 200) {
    // let data = rmMisjudgedEvt(res.data) // 過濾掉誤判
    let data = res.data
    let data2 = null
    if (params?.beforeId?.length > 0 && data.length > 0) { // 因為beforeId回傳 舊([0])至新([len - 1]), 所以要排成 新([0])至舊([len - 1]) 方便整理資料
      data2 = sortEvnetNew2Old(data) // 時間排序
    }

    res.data = data2 ? data2 : data
    return res
  }
  return res
}

export function apiPostDashboardLog(data) {
  return apiClient.post('/api/web/dashboard/log', data)
}

export function apiEditBoviaLprEvent(data) {
  return apiClient.post('/api/web/va/bovia/lpr/event/update', data)
}

export function apiEditBoviaFrEvent(data) {
  return apiPost('/api/web/va/bovia/fr/event/update', data)
}

export function apiGetConnections() {
  return apiClient.get('/api/web/connections')
}

export function apiGetTagList() {
  return apiClient.get('/api/web/va/bovia/lpr/tag/list')
}

export function apiGetFrTagList() {
  return apiClient.get('/api/web/va/bovia/fr/tag/list')
}

export function apiGetBoviaLprCodebooks() {
  return apiClient.get('/api/web/va/bovia/lpr/codebooks')
}

// 2024.08.08 v1.19.0 棄用
// export function apiSearchVideo(data, signal) {
//   return apiClient.post('/api/web/video/search', data, signal)
// }

export function apiGetVideo(id) {
  return apiClient.get(`/api/web/video?id=${id}`)
}

export function apiVideoUpdate(data) {
  return apiClient.post('/api/web/video/update', data)
}

export function apiVideoDelete(data) {
  return apiClient.post('/api/web/video/delete', data)
}

export function apiGetVideoGPS(vid) {
  return apiClient.get(`/api/web/video/gps?vid=${vid}`)
}

export function apiCreateUser(data) {
  return apiClient.post('/api/web/user', data)
}

export function apiGetUser() {
  return apiClient.get('/api/web/user')
}

export function apiGetUserIndex(index) {
  return apiClient.get(`/api/web/user?index=${index}`)
}

export function apiGetUserByUid(uid) {
  return apiClient.get(`/api/web/user?uid=${uid}`)
}

export function apiEditUser(data) {
  return apiPut('/api/web/user', data)
  // return apiClient.put('/api/web/user', data)
}

export function apiLockUser(data) {
  return apiClient.post('/api/web/user/lock', data) // data: {id, suspend}
}

export function apiUnlockUser(id) {
  return apiClient.post('/api/web/user/unlock', {id})
}

// ----------------- 跟webrtc有關的api -------------------- //

export function callNotificationWS(token) {
    return new WebSocket(`wss://${domain_name}/api/web/notifications?Authorization=${token}`);
}

export function callRoomWS(room, token) {
    return new WebSocket(`wss://${domain_name}/webrtc-mgr/${room}/ws?Authorization=${token}`);
}

export function getUserChannedId(uid) {
    let params = { uid }
    return apiClient.get('/api/web/user', { params })
}

export function callRoomAddressApi(id, typ) {
    // 群組要傳groupID 一對一通話會由系統自動產生
    if (typ == 'user') return apiClient.get('/api/web/webrtc')
    if (typ == 'group') return apiClient.get(`/api/web/webrtc?groupId=${id}`)
}

export function callSdpSpeakApi(key, offer, id) {
    //console.log('sdp speak', { id, key, offer })
    return apiClient.post(`${base_apiurl}/webrtc-mgr/${key}/sdp/speak`, { id, key, offer })
}

export function callSdpListenApi(room, offer, target) {
    //console.log('sdp listen', { offer, target })
    return apiClient.post(`${base_apiurl}/webrtc-mgr/${room}/sdp/listen`, { offer, target })
}

export function callRoomMemberApi(room) {
    //console.log('callRoomMemberApi', room)
    return apiClient.get(`${base_apiurl}/webrtc-mgr/${room}/members`)
}

export function callCmdApi(data) {
    //return apiClient.post('/api/web/notification/user', data)
    //console.log(`${base_apiurl}/api/v3/notification/user`, data)
    return apiClient.post(`${base_apiurl}/api/v3/notification/user`, data)
}

export function apiPostNotificationUser(data) {
    return apiClient.post('/api/web/notification/user', data)
}

export function leaveRoom(room) {
    return apiClient.post(`${base_apiurl}/webrtc-mgr/${room}/leave`)
}

export function callChannelApi() {
  return apiClient.get('/api/web/ptt/channels')
}

/****** PTZ API ******/
export function apiPostDeviceCtrl(params) {
  return apiClient.post('/api/web/device/ctrl', params)
}

/**********************/

/** ------ Role Management ------ **/
function sortRoleName(a, b) {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()
  if (nameA > nameB) {
    return 1
  } else if (nameA < nameB) {
    return -1
  }

  return 0
}
export async function getRoles() {
  // return apiClient.get('/api/web/roles')
  const res = await apiGet('/api/web/roles')
  if (apiCheckStatus(res)) {
    let data = res.data

    data.sort(sortRoleName)
    res.data = data
    return res
  }

  return res
}

export function createRole(data) {
  return apiClient.post('/api/web/role', data)
}

export function editRole(data) {
  // return apiClient.put('/api/web/role', data)
  return apiPut('/api/web/role', data)
}

export function deleteRole(id) {
  return apiClient.delete('/api/web/role', { data: { id: id } })
}

/** ------ User Management ------ **/
export function apiGetUserInfoList() {
  return apiClient.get('/api/web/user-info/list')
}

export function apiGetUserInfo(userIndex) {
  return apiClient.get(`/api/web/user-info?index=${userIndex}`)
}
/*---------------------------------*/

export function apiGetSettingWeb() {
  return apiClient.get('/api/web/setting/web')
}

export function apiPutSettingWeb(data) {
  return apiClient.put('/api/web/setting/web', { "setting": data })
}

export function apiGetSettingLPR() {
  return apiClient.get('/api/web/setting/lpr')
}

export function apiPutSettingLPR(data) {
  return apiClient.put('/api/web/setting/lpr', data)
}

export function apiGetSettingGeneral() {
  return apiClient.get('/api/web/setting/general')
}

export function apiPutSettingGeneral(data) {
  return apiClient.put('/api/web/setting/general', data)
}

export function apiGetSystemFeature() {
  return apiClient.get('/api/web/system/feature')
}

export function apiPutSystemFeature(data) {
  return apiClient.put('/api/web/system/feature', data)
}

export function apiGetWebPreferences() {
  return apiClient.get('/api/web/preferences')
}


/*********************************************************************
 * Device AI Setting
 *********************************************************************/

// 取得某設備類型default-setting(系統預設)  
export function apiGetDefaultSetting(deviceModelId) {
  return apiClient.get(`/api/web/default/setting?deviceModelId=${deviceModelId}`)
} 

// 取得某設備的 user-setting(設備客製)
export function apiGetUserSetting(userId) {
  return apiClient.get(`/api/web/user/setting?userId=${userId}`)
}

// 新增某設備的 user-setting(設備客製)
export function apiPostUserSetting(data) {
  return apiClient.post('/api/web/user/setting', data)
}

// 編輯某設備的 user-setting(設備客製)
export function apiPutUserSetting(data) {
  return apiClient.put('/api/web/user/setting', data)
}

/*--------------------------------------------------------------------
 * Web Announcement
 ---------------------------------------------------------------------*/
export function apiGetWebAnnouncements() {
  return apiClient.get('/api/web/announcements')
}

export function apiPostWebAnnouncement() {
  return apiClient.post('/api/web/announcement?')
}

export function apiPutWebAnnouncement() {
  return apiClient.put('/api/web/announcement')
}

export function apiDeleteWebAnnouncement() {
  return apiClient.delete('/api/web/announcement')
}

/*--------------------------------------------------------------------
 * Get App List
 */
export function apiGetAppList() {
  return apiClient.get(`${base_apiurl}/api/app/list`)
}

/*--------------------------------------------------------------------
 * Get App List
 */
// Fr DB APIs
const apiFrHuman = `/api/web/va/bovia/fr/human`
export function apiPostFrHumanSearch(data) {
  // frInfoLv: Fr DB list, 帶入 '{}' 就可以取得全部
  return apiPost(`${apiFrHuman}/search`, data)
}
export function apiGetFrHuman(data) {
  // frInfoLv: Fr DB recode
  return apiGet(`${apiFrHuman}`, data)
}
export function apiPostFrHuman(data) {
  // frInfoLv: 新增人物資訊
  return apiPost(apiFrHuman, data)
}
export function apiPutFrHuman(data) {
  // frInfoLv: 編輯人物資訊
  return apiPut(apiFrHuman, data)
}
export function apiDeleteFrHuman(data) {
  // frInfoLv: 刪除人物資訊
  return apiDelete(apiFrHuman, { data })
}
const apiFrHumanFeature = `/api/web/va/bovia/fr/human/feature`
export function apiPostFrHumanFeature(data) {
  // frInfoLv: 新增人物特徵值
  return apiPost(apiFrHumanFeature, data)
}
export function apiPutFrHumanFeature(data) {
  // frInfoLv: 修改人物特徵值 啟用/停用
  return apiPut(apiFrHumanFeature, data)
}
export function apiDeleteFrHumanFeature(data) {
  // frInfoLv: 刪除人物特徵值
  return apiDelete(apiFrHumanFeature, { data })
}
const apiFrHumanFeatureTask = `/api/web/va/bovia/fr/human/feature/task`
export function apiPostFrHumanTaskFeatureSearch(data) {
  // frInfoLv: Fr feature task list
  return apiPost(`${apiFrHumanFeatureTask}/search`, data)
}
export function apiPostFrHumanFeatureTask(data) {
  // frInfoLv: 新增人物特徵值計算任務
  return apiPost(apiFrHumanFeatureTask, data)
}
export function apiDeleteFrHumanFeatureTask(data) {
  // frInfoLv: 刪除人物特徵值計算任務
  return apiDelete(apiFrHumanFeatureTask, { data })
}
export function apiPostFrTag(data) {
  // frInfo: 新增人物標記
  return apiPost(`/api/web/va/bovia/fr/tag`, data)
}
// export function apiPutFrTag(data) {
//   // frInfo: 編輯人物標記
//   return apiPut(`/api/web/va/bovia/fr/tag`, data)
// }
export function apiDeleteFrTag(data) {
  // frInfo: 刪除人物標記
  return apiDelete(`/api/web/va/bovia/fr/tag`, { data })
}
// export function apiPostFrWanted(data) {
//   // frInfo: 新增人物通緝
//   return apiPost(`/api/web/va/bovia/fr/wanted`, data)
// }
// export function apiPutFrWanted(data) {
//   // frInfo: 編輯人物通緝
//   return apiPut(`/api/web/va/bovia/fr/wanted`, data)
// }
// export function apiDeleteFrWanted(data) {
//   // frInfo: 刪除人物通緝
//   return apiDelete(`/api/web/va/bovia/fr/wanted`, data)
// }

// 日誌
export function apiPostGroupLogSearchAccess(data) {
  return apiPost(`/api/web/group/log/search/access`, data)
}
// export function apiPostGroupLogSearchResource(data) {
//   return apiPost(`/api/web/group/log/search/resource`, data)
// }

// 縮時攝影
const apiExportVideoTask = `/api/web/export-video/task`
export function apiGetExportVideoTasks() {
  return apiGet(`${apiExportVideoTask}s`)
}
export function apiPostExportVideoTask(data) {
  return apiPost(apiExportVideoTask, data)
}
export function apiDeleteExportVideoTask(data) {
  return apiDelete(apiExportVideoTask, { data })
}


/*--------------------------------------------*/
// 系統與服務 伺服器 設定
/*--------------------------------------------*/

// export function apiGetServerStatus(data) {
//   // sid string Required. Server identity.
//   return apiGet('/api/web/server/status', data)
// }

export function apiPutServer(data) {
  return apiPut('/api/web/server', data)
}

export function apiPutServerStreamer(data) {
  return apiPut('/api/web/server/streamer', data)
}

export function apiPutServerLpr(data) {
  return apiPut('/api/web/server/lpr', data)
}

export function apiPutServerFr(data) {
  return apiPut('/api/web/server/fr', data)
}

export function apiPostServerRestart(data) {
  return apiPost('/api/web/server/restart', data)
}

/*--------------------------------------------*/
// 系統與服務 憑證管理
/*--------------------------------------------*/
// 憑證
export function apiServerMgrCert(data, method = ApiMethod.GET) {
  let path = `${base_apiurl}/server-mgr/cert`
  // path = `/server-mgr/cert`
  switch (method) {
    case ApiMethod.GET:
      return apiGet(path)
      // return apiClient.get(path)
    case ApiMethod.POST: // 上傳檔案
      // return apiPost(path, data)
      return apiPostFile(path, data)
    case ApiMethod.PUT:
      return apiPut(path, data)
    default:
      return null
  }
}
// 版本資訊
export function apiGetServerMgrCore() {
  return apiGet(`${base_apiurl}/server-mgr/core`)
}

/*--------------------------------------------*/
// 停車場管理
/*--------------------------------------------*/
export function apiGetAcParkingList() {
  return apiGet(`/api/web/ac/parking/list`)
}

export function apiPostAcParkingSearch(data) {
  return apiPost(`/api/web/ac/parking/search`, data)
}

export function apiPutAcParkingNum(data) {
  return apiPut(`/api/web/ac/parking/num`, data)
}
